import { PixelCrop } from "react-image-crop";

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
    image,
    canvas,
    crop,
    scale = 1,
    rotate = 0
) {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
        throw new Error("No 2d context");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
// canvas.width = 960;
// canvas.height = 960;
        //   const aspectRatio = image.width / image.height;
        //   let newWidth, newHeight;

        //   if (aspectRatio > 1) {
        //       newWidth = 960;
        //       newHeight = newWidth / aspectRatio;
        //   } else {
        //       newHeight = 960;
        //       newWidth = newHeight * aspectRatio;
        //   }

        //   canvas.width = newWidth;
        //   canvas.height = newHeight;

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.rotate(rotateRads);
    ctx.scale(scale, scale);
    ctx.translate(-centerX, -centerY);
        //   ctx.drawImage(image, 0, 0, newWidth, newHeight);
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
    );

    ctx.restore();
}
