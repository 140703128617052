import axios from "axios";
import md5 from 'md5-hash'
import jwt_decode from "jwt-decode";
import Gestiondeurl from './url.service'
import AxiosApi from './axios.service'

// const API_URL = "http://192.168.1.59:8000/api/auth/";
// const API_URL = "http://192.168.1.60/api/react/";

// const register = (name, email, password) => {
//   return axios.post(API_URL + "signup", {
//     name,
//     email,
//     password,
//   });
// };

const centro = async ()=>{
  // let localizacion = "FUERA DE DIFERENTE";
  const res = await axios.get("https://api.ipify.org/?format=json");
  console.log(res.data.ip);
  switch (res.data.ip) {
    case "80.38.219.243":
      return("V");
      break;
    case "88.10.196.152":
      return("S");
      break;
    case "90.174.90.152":
      return("A");
      break;
    default:
      return(0);
  }

}
const getObservaciones = async ()=>{
  let url = 'https://empleados.diferente.es/api/observacionesempleado';
  AxiosApi.axiosApi
    .get(url)
    .then((response) => {

        console.log(response);
        localStorage.setItem("observaciones", JSON.stringify(response.data.user));

      
    })
    .catch((error) => {
       
        console.log(error);
    });
}
const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  'Accept': 'application/json',
}
const login = async (usuario, password) => {
  // const urlbase = await Gestiondeurl.DevuelveUrl()
  // console.log(urlbase)
  return axios
    .post('https://cldn.cloudlsf.es/api/auth/login',
   
    {
      
      "email": usuario,
      "password": (password),
    },{
      headers:headers
    })
    .then(async (response) => {
      // console.log("response", response.data)
      // console.log("token",response.data.token)
      if (response.data.token) {
        var token = response.data.token;
        localStorage.setItem("token", token);
        var nombre=response.data.nombre
        localStorage.setItem("usuario", nombre);
        var rol = response.data.rol
        var apikey=response.data.apikey
        var nombreempresa=response.data.nombreempresa
        console.log('token=>',token)
        
        localStorage.setItem("rol", rol);
        localStorage.setItem("respuesta", JSON.stringify(response.data));
       
        // localStorage.setItem("token", token);

      }

      return (response.data);

    });
};
const getDatos =  ()=>{
  const datos=JSON.parse(localStorage.getItem('respuesta'))
  return datos
}
const logout = () => {
  localStorage.removeItem("usuario");
  localStorage.removeItem("token");
  localStorage.removeItem("codcli");
  localStorage.removeItem("observaciones");
};

const getCurrentUser = () => {
  if (localStorage.getItem("usuario") === null) {
    return ({
      "id": "00000",
      "nombre": "Perico Palotes",
      "rol": "user"
    })
  }
  // console.log("getcurrentuser", localStorage.getItem("usuario"))
  if (localStorage.getItem("usuario")) {
    console.log('AuthService.getCurrentUser ',
        localStorage.getItem("usuario"),
        JSON.parse(localStorage.getItem("observaciones"))
    );
    return ([
      localStorage.getItem("usuario"),JSON.parse(localStorage.getItem('observaciones'))]);
  } else {
    return ({
      "id": "000000",
      "nombre": "Perico Palotes",
      "rol": "user"
    })

  };
}
const tiempoHastaExpirar = ()=>{
  // console.log((jwt_decode(localStorage.getItem("token")).exp - Date.now()/1000)*1000)
  return ((jwt_decode(localStorage.getItem("token")).exp - Date.now()/1000)*1000)
}
const comprobarToken = () => {
  // console.log('Llamada a comprobarToken')
  if (localStorage.getItem("token")) {

    // console.log('expiration ' + jwt_decode(localStorage.getItem("token")).exp + ' | ' + Date.now() / 1000)
    // console.log('Token expirado=>'+jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000)
    // return (jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000)
    return false
  } else {
    // No hay token
    console.log('No hay Token')
    return true
  }

}
const actualizarToken = () => {
  if (localStorage.getItem("token")) {

    console.log('expiration ' + jwt_decode(localStorage.getItem("token")).exp + ' | ' + Date.now() / 1000)
    console.log(jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000)
  }


}

const comprobarRol = (nivel) => {
  const niveles =[
    ["*", "admin"],
    ["*", "admin", "adjuntocontrol"],
    ["*", "admin", "adjuntocontrol", "encargado"]
  ]
  try{
    const rol = JSON.parse(localStorage.getItem('observaciones')).rol;
    return(niveles[nivel].includes(rol) ? true :false)
  } catch
  {
    return false
  }
  
  const usuario = JSON.parse(localStorage.getItem('usuario'))

  console.log('rol del usuario' + usuario)
  return (usuario.rol)
}
const exportar= {
  // register,
  login,
  logout,
  getCurrentUser,
  comprobarToken,
  actualizarToken,
  comprobarRol,
  tiempoHastaExpirar,
  centro,
  getObservaciones,
  getDatos,
};
export default exportar