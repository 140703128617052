import React, { useState, useRef, useEffect } from "react";
import { useMiContexto } from "../../contexto";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import AxiosApi from "./../../servicios/axios.service";
import AuthService from "./../../servicios/auth.service";
import "react-image-crop/dist/ReactCrop.css";
import { useTheme } from "@mui/material/styles";
import { Button } from "@material-ui/core";
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 95,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}



function getImageFileType(image) {
    const extensionMatch = image.src.match(/\.(\w+)$/);
    return extensionMatch ? extensionMatch[1].toLowerCase() : "png";
}

function SubirImagen({ estado, setEstado }) {
    const [imgSrc, setImgSrc] = useState("");
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const hiddenAnchorRef = useRef(null);
    const blobUrlRef = useRef("");
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState(1 / 1);
    const { miEstado, setMiEstado } = useMiContexto();
    const theme = useTheme();
    console.log(theme);
    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImgSrc(reader.result?.toString() || "")
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }
    // useEffect  (()=>{
    //     setObservaciones(AuthService.getObservaciones())
    // },[])
    async function uploadToBackend(imageData, fileType) {
        const formData = new FormData();
        formData.append("image", imageData, `resized_image.${fileType}`);

        try {
            const response = await AxiosApi.axiosApi.post(
                "https://ficha.online/api/premium/subirimagenempresa",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                // setEstado(estado++)
                // setMiEstado(AuthService.getObservaciones());
                console.log("Imagen subida exitosamente al backend");
            } else {
                console.error("Error al subir la imagen al backend");
            }
        } catch (error) {
            console.error("Error de red:", error);
        }
    }

    async function onDownloadCropClick() {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error("Crop canvas does not exist");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY
        );
        const ctx = offscreen.getContext("2d");
        if (!ctx) {
            throw new Error("No 2d context");
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height
        );

        const blob = await offscreen.convertToBlob({
            type: "image/jpg",
        });

        if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current);
        }
        blobUrlRef.current = URL.createObjectURL(blob);

        if (hiddenAnchorRef.current) {
            hiddenAnchorRef.current.href = blobUrlRef.current;
            hiddenAnchorRef.current.click();
        }
    }
    async function resizeImage(image, crop) {
        return new Promise((resolve) => {
            const image = imgRef.current;
            const previewCanvas = previewCanvasRef.current;
            if (!image || !previewCanvas || !completedCrop) {
                throw new Error("Crop canvas does not exist");
            }
            const aspectRatio = image.naturalWidth / image.naturalHeight;
            // const scaleX = image.naturalWidth / image.width;
            // const scaleY = image.naturalHeight / image.height;
            let scale = 1;
            if (aspectRatio > 1) {
                // scale = image.naturalWidth / image.width;
                scale = 960 / completedCrop.width;
            } else {
                scale = 960 / completedCrop.height;
                
            }
            const scaleX = 960/ image.naturalWidth ;
            const scaleY = 960 /image.naturalHeight;

            const offscreen = new OffscreenCanvas(
                completedCrop.width * scale,
                completedCrop.height * scale
            );
            console.log(
                completedCrop,
                scaleX,
                scaleY,
                aspectRatio,
                offscreen,
                previewCanvas
            );
            const ctx = offscreen.getContext("2d");
            if (!ctx) {
                throw new Error("No 2d context");
            }

            ctx.drawImage(
                previewCanvas,
                0,
                0,
                previewCanvas.width,
                previewCanvas.height,
                0,
                0,
                offscreen.width,
                offscreen.height
            );
            offscreen
                .convertToBlob({
                    type: "image/jpeg",
                    quality: 0.95,
                })
                .then((blob) => {
                    console.log(blob);
                    resolve(blob);
                });
            // offscreen.convertToBlob({
            //     type: "image/png",
            // });
        });
    }
    async function resizeAndUpload(image, crop) {
        try {
            const resizedImage = await resizeImage(image, crop);
            const fileType = getImageFileType(image);

            // Subir al backend (Asumiendo que tienes una función en tu backend para manejar esto)
            await uploadToBackend(resizedImage, fileType);
        } catch (error) {
            console.error("Error al procesar la imagen:", error);
        }
    }
    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate
                );
            }
        },
        100,
        [completedCrop, scale, rotate]
    );

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined);
        } else {
            setAspect(16 / 9);

            if (imgRef.current) {
                const { width, height } = imgRef.current;
                const newCrop = centerAspectCrop(width, height, 16 / 9);
                setCrop(newCrop);
                setCompletedCrop(convertToPixelCrop(newCrop, width, height));
            }
        }
    }

    const handleUploadButtonClick = async () => {
        if (completedCrop && imgRef.current) {
            try {
                await resizeAndUpload(imgRef.current, completedCrop);
            } catch (error) {
                console.error("Error al procesar la imagen:", error);
            }
        }
    };

    return (
        <>
            <h1>Subir Imagen</h1>
            <div className="App">
                <div className="Crop-Controls">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={onSelectFile}
                    />
                    <div>
                        <label htmlFor="scale-input">Scale: </label>
                        <input
                            id="scale-input"
                            type="number"
                            step="0.1"
                            value={scale}
                            disabled={!imgSrc}
                            onChange={(e) => setScale(Number(e.target.value))}
                        />
                    </div>
                    <div>
                        <label htmlFor="rotate-input">Rotate: </label>
                        <input
                            id="rotate-input"
                            type="number"
                            value={rotate}
                            disabled={!imgSrc}
                            onChange={(e) =>
                                setRotate(
                                    Math.min(
                                        180,
                                        Math.max(-180, Number(e.target.value))
                                    )
                                )
                            }
                        />
                    </div>
                    <div>
                        <button onClick={handleToggleAspectClick}>
                            Toggle aspect {aspect ? "off" : "on"}
                        </button>
                    </div>
                </div>
                {!!imgSrc && (
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                    >
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{
                                transform: `scale(${scale}) rotate(${rotate}deg)`,
                            }}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                )}
                {!!completedCrop && theme && (
                    <>
                        <div 
                        style={{ display: "none" }}
                        >
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    // display: "none",
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                }}
                            />
                        </div>
                        <div>
                            {/* <button onClick={onDownloadCropClick}>
                                Download Crop
                            </button> */}

                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                }}
                                // color={theme.palette.primary.main}
                                size="large"
                                // sx={{ color: "red", backgroundColor: "blue" }}
                                onClick={() => {
                                    handleUploadButtonClick();
                                }}
                            >
                                Subir
                            </Button>
                            <a
                                href="#hidden"
                                ref={hiddenAnchorRef}
                                download
                                style={{
                                    position: "absolute",
                                    top: "-200vh",
                                    visibility: "hidden",
                                }}
                            >
                                Hidden download
                            </a>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default SubirImagen;
