import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { MDBSpinner } from 'mdb-react-ui-kit';
import Gestiondeurl from './../servicios/url.service'
import AxiosApi from './../servicios/axios.service'
import { createTheme, ThemeProvider } from '@material-ui/core';
import {Radio} from '@mui/material';
import {RadioGroup} from '@mui/material';
import {FormControlLabel} from '@mui/material';
import {FormControl} from '@mui/material';
import {FormLabel} from '@mui/material';
// import FormLabel from "@mui/material/FormLabel";
const theme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: '#e3007e',
    },
    secondary: {
      main: '#f50057',
    }
  }
});
// var fechafin = DateTime.now().toFormat('yyyyMMdd')
// // var fechainicio = DateTime.local().startOf('year').toFormat('yyyyMMdd')
// var fechainicio = DateTime.local().minus({ 'years': 1 }).startOf('month').toFormat('yyyyMMdd')


function Margenes(props) {
  const enlacedetalle = (p) => {
    // console.log(p.row.mes.toString().length)
    if (p.row.mes && p.row.mes.toString().length < 2) {
      p.row.mes = '0' + p.row.mes
    }
    return (
      <Link to={`/detallemes/${p.row.Año}/${p.row.mes}/${props.codalm}`}>{p.value}</Link>
    )
  }
  const [fechainicio, setFechainicio]=useState(DateTime.local().minus({ 'days': 1 }).toFormat('yyyyMMdd'))
const [fechafin, setFechafin]=useState(DateTime.local().minus({ 'days': 1 }).toFormat('yyyyMMdd'))
  const [value, setValue] = useState('ayer');
  const handleChange = (event) => {
    console.log(event.target.value)
    setValue(event.target.value);
    if (event.target.value=='ayer'){
      setFechainicio(DateTime.local().minus({ 'days': 1 }).toFormat('yyyyMMdd'))
      setFechafin(DateTime.local().minus({ 'days': 1 }).toFormat('yyyyMMdd'))
    }
    if (event.target.value=='semana'){
      setFechainicio(DateTime.local().minus({ 'days': 7 }).toFormat('yyyyMMdd'))
      setFechafin(DateTime.local().minus({ 'days': 1 }).toFormat('yyyyMMdd'))
    }
    if (event.target.value=='estemes'){
      setFechainicio(DateTime.local().startOf('month').toFormat('yyyyMMdd'))
      setFechafin(DateTime.local().minus({ 'days': 1 }).toFormat('yyyyMMdd'))
    }
    if (event.target.value=='mespasado'){
      setFechainicio(DateTime.local().minus({'months' : 1}).startOf('month').toFormat('yyyyMMdd'))
      setFechafin(DateTime.local().minus({'months' : 1}).endOf('month').toFormat('yyyyMMdd'))
    }
    if (event.target.value=='esteano'){
      setFechainicio(DateTime.local().startOf('year').toFormat('yyyyMMdd'))
      setFechafin(DateTime.local().minus({ 'days': 1 }).toFormat('yyyyMMdd'))
    }
    if (event.target.value=='anopasado'){
      setFechainicio(DateTime.local().minus({'year':1}).startOf('year').toFormat('yyyyMMdd'))
      setFechafin(DateTime.local().minus({'year':1}).endOf('year').toFormat('yyyyMMdd'))
    }
    if (event.target.value=='mes'){
      setFechainicio(DateTime.local().minus({'days':31}).toFormat('yyyyMMdd'))
      setFechafin(DateTime.local().minus({'days':1}).toFormat('yyyyMMdd'))
    }
    if (event.target.value=='ano'){
      setFechainicio(DateTime.local().minus({'days':366}).toFormat('yyyyMMdd'))
      setFechafin(DateTime.local().minus({'days':1}).toFormat('yyyyMMdd'))
    }
    
   
  };
  const getMargen = (params) =>{
// console.log (params.row.ventastpv, params.row.costetpv)
    const beneficio= params.row.ventastpv - params.row.costetpv
    const margen =beneficio / params.row.ventastpv
    return margen

  }
  const getVentas = (params) =>{
    // console.log (params.row)

        return (
          params.row.ventastpv+
          params.row.ventasfacturas+
          params.row.ventasonline+
          params.row.ventasalbaranes+
          params.row.ventasinternas)
    
      }
  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    // { field: 'id', headerName: 'ID', width: 90 },
    { field: 'almacen', headerName: 'Almacén', width: 90, align:'center', headerClassName: 'Datagrid-rotado',},
    { field: 'seccion', headerName: 'Sección', width: 115, align:'center',headerClassName: 'Datagrid-rotado', },
    {
      field: 'ventastpv',
      headerName: 'Ventas TPV',
      width: 160,
      headerClassName: 'Datagrid-rotado',
    align:'center',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: 'costetpv', headerName: 'Coste TPV', width: 115, align:'center',headerClassName: 'Datagrid-rotado',

      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
      
    },
    {
      field: 'margenTPV', headerName: 'Margen TPV', width: 115,headerClassName: 'Datagrid-rotado',

      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 100).toLocaleString();
        return `${valueFormatted} %`;
      },
      valueGetter: getMargen,
      align:'center',
      
    },
    {
      field: 'ventasfacturas', headerName: 'Ventas por Facturas', width: 115, align:'center',headerClassName: 'Datagrid-rotado',

      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
      {
        field: 'ventasonline', headerName: 'Ventas online', width: 115, align:'center',headerClassName: 'Datagrid-rotado',
  
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
  
          const valueFormatted = Number(params.value * 1).toLocaleString();
          return `${valueFormatted} €`;
        },
      
    },
    {
      field: 'ventasalbaranes', headerName: 'Ventas por albaranes', width: 115, align:'center',headerClassName: 'Datagrid-rotado',

      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: 'ventasinternas', headerName: 'Ventas internas', width: 115, align:'center',headerClassName: 'Datagrid-rotado',

      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
      {
        field: 'totalventas', headerName: 'Total Ventas', width: 115,headerClassName: 'Datagrid-rotado',
  
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
  
          const valueFormatted = Number(params.value * 1).toLocaleString();
          return `${valueFormatted} €`;
        },
        valueGetter: getVentas,
        align:'center',
        
      },
      {
        field: 'comprasinternas', headerName: 'Compras internas', width: 115, align:'center',headerClassName: 'Datagrid-rotado',
  
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
  
          const valueFormatted = Number(params.value * 1).toLocaleString();
          return `${valueFormatted} €`;
        },
      },
      {
        field: 'compras', headerName: 'Compras', width: 115, align:'center',headerClassName: 'Datagrid-rotado',
  
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
  
          const valueFormatted = Number(params.value * 1).toLocaleString();
          return `${valueFormatted} €`;
        },
      },

  ];

  const rows = [
    {}, {}

  ];
  const [cargando, setCargando] = useState(true)

  const [filas, setFilas] = useState([

  ])

  var datos = {}
  useEffect(() => {
    const getDatos = async () => {
      setCargando(true)
      let et = []
      let v = []
      let c = []
      const urlbase = await Gestiondeurl.DevuelveUrl()
      // console.log(urlbase)
      // Gestiondeurl.DevuelveUrl()
      // .then (url=>{
      //   console.log(url)
      // })
      let url = urlbase+ 'margenes'
      // let url = 'http://192.168.1.60/api/react/' + 'margenes'
      console.log(DateTime.fromFormat(fechainicio,'yyyyMMdd').setLocale('es').toFormat('dd - MMMM - yyyy'), fechafin)
      let data = {
        "fechainicio": fechainicio,
        'fechafin': fechafin,
        'almacen': props.codalm,

      }
      let config = {
        headers: { Authorization: `Bearer ` + localStorage.getItem('token') }


      }
      AxiosApi.axiosApi
        .post(url, data)
        .then((response) => {
          // console.log(response.data)

          setFilas(response.data.response)
          setCargando(false)
          // response.data.response.map((e) => {
          //   et.push(e.e.almacen)
          //   v.push(e.seccion)
          //   c.push(e.clientes)
          // })






        })
        .catch((error) => {
          setCargando(false)
          console.log(error);
        });

    }
    getDatos();

  }, [value]);

  return (
    <ThemeProvider theme={theme}>
     
        <>
                      <h1>Márgenes</h1>
              <AppBar
                // style={{ background: '#e3007e' }} 
                sx={{
                  backgroundColor: '#e3007e',
                }}
                position="static">
                <Toolbar>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Período:
                    &nbsp; Del { DateTime.fromFormat(fechainicio,'yyyyMMdd').setLocale('es').toFormat('dd/MMMM/yyyy')}
                    &nbsp; al {DateTime.fromFormat(fechafin,'yyyyMMdd').setLocale('es').toFormat('dd/MMMM/yyyy')}
                    
                    </FormLabel>
                    
                    <RadioGroup
                    value={value}
                    onChange={handleChange}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value="ayer" control={<Radio disabled={cargando}
                      />} label="Ayer" />
                      <FormControlLabel value="semana" control={<Radio 
                      disabled={cargando}/>} label="Última semana" />
                      <FormControlLabel value="estemes" control={<Radio 
                      disabled={cargando}/>} label="Este mes" />
                      <FormControlLabel value="mespasado" control={<Radio disabled={cargando}/>} label="Mes pasado" />
                      <FormControlLabel value="esteano" control={<Radio disabled={cargando}/>} label="Este Año" />
                      <FormControlLabel value="anopasado" control={<Radio disabled={cargando}/>} label="Ano pasado" />
                      <FormControlLabel value="mes" control={<Radio disabled={cargando}/>} label="ültimos 30 días" />
                      <FormControlLabel value="ano" control={<Radio disabled={cargando}/>} label="ültimo Año" />
                 
                    </RadioGroup>
                  </FormControl>
                </Toolbar>
              </AppBar>
        </>
        {
        !cargando ?
          (
            <>

              <div style={{ height: '500px' }}>
                <DataGrid
                // className="Datagrid-rotado"
                  headerHeight={123}
                  rows={filas}
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 15, 25]}
                  pageSize={25}

                />

              </div>
            </>
          ) :
          // <CircularProgress/>
          <MDBSpinner className='mx-6' color='main.secondary'>
            {/* <span className='visually-hidden'>Loading...</span> */}
          </MDBSpinner>

      }
    </ThemeProvider>

  )

}

export default Margenes