
const DevuelveUrl = () => {
  return('');
  return("http://difevargas.actual.cloudlsf.es:1404/api/react/")
    // return("http://192.168.1.60/api/react/");

    // return("http://difevargas.actual.cloudlsf.es:1404/api/react/")
  // return new Promise((resolve, reject) => {
  //   // do some async task
    
  //   let url = window.location.href.split("/");
  //   if (url[2].includes("difevargas")) {
  //     resolve("http://difevargas.actual.cloudlsf.es:1404/api/react/");
  //   }
  //   if (url[2].includes("localhost")) {
  //     // Estamos en desarrollo
  //     try {
  //       fetch("http://difevargas.actual.cloudlsf.es:1404/api/react/")
  //         .then((res) => {
  //           if (res.status === 404) {
  //             // Estamos en desarrollo y en Diferente
  //             resolve("http://192.168.1.60/api/react/");
  //           } else {
  //             resolve("http://difevargas.actual.cloudlsf.es:1404/api/react/");
  //           }
  //         })
  //         .catch((e) => {
  //           console.log(e);
  //           resolve("http://192.168.1.60/api/react/");
  //         });
  //     } catch (err) {
  //       console.log(err);
  //       resolve("http://192.168.1.60/api/react/");
  //     }
  //   } else {
  //     resolve("http://192.168.1.60/api/react/");
  //   }
  // });
};
const urlservice = {
  DevuelveUrl,
};
export default urlservice
// export default {
//   DevuelveUrl,
// };
