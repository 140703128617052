export const colores = [
    { Congelados: "rgba(51, 102, 204, 0.25)" },
    { Charcutería: "rgba(204, 51, 102, 0.25)" },
    { Pastelería: "rgba(255, 204, 0, 0.25)" },
    { Carnicería: "rgba(204, 102, 51, 0.25)" },
    { Cocinados: "rgba(51, 204, 153, 0.25)" },
    { Planta: "rgba(255, 102, 0, 0.25)" },
    { Bodega: "rgba(204, 153, 102, 0.25)" },
    { Auxiliar: "rgba(204, 51, 204, 0.25)" },
];

export const conditionalRowStyles = colores.map((color) => {
    const tipo = Object.keys(color)[0];
    const valor = color[tipo];
    return {
        when: (row) => row.centrocoste === tipo,
        style: () => ({ backgroundColor: valor }),
    };
});

export const customStyles = {
  table: {
    style: {
      backgroundColor: "transparent", // Establece el fondo de las celdas como transparente
    },
  },
  subHeader: {
    style: {
      backgroundColor: "transparent", // Establece el fondo de las celdas como transparente
    },
  },
  headRow: {
    style: {
      backgroundColor: "transparent", // Establece el fondo de las celdas como transparente
    },
  },
  rows: {
    style: {
      backgroundColor: "transparent", // Establece el fondo de las filas como transparente
    },
  },
  // headCells: {
  //   style: {
  //     backgroundColor: 'transparent', // Establece el fondo de las celdas de encabezado como transparente
  //   },
  // },
  cells: {
    style: {
        // padding:'0',
        // flex:'unset',
      backgroundColor: 'transparent', // Establece el fondo de las celdas como transparente
    },
  },
  pagination: {
    style: {
      backgroundColor: "transparent",
    },
  },
};