import MargenesCentrocoste from "../component/MargenesCentroCoste";
// import Margenes from "../component/Margenes";
import { Box, Grid, Paper } from '@material-ui/core';
import { styled } from '@material-ui/styles';
function Margenes() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"

      maxWidth="99%">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        maxWidth="99%"
      // sx={{ flexGrow: 1, width: '90%' }}
      >
        <Grid

          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {/* <Grid item xs={12} sm={6}>

          </Grid> */}
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="VCO" nombre="Vargas Cocinados" />

          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="SCO" nombre="Sardinero Cocinados" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="VC" nombre="Vargas Carnicería" />
          </Grid>
          <Grid item xs={12} sm={3}>

            <MargenesCentrocoste periodo="estemes" centrodecoste="SC" nombre="Sardinero Carnicería" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="VCH" nombre="Vargas Charcutería" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="SCH" nombre="Sardinero Charcutería" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="VCG" nombre="Vargas Congelados" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="SCG" nombre="Sardinero Congelados" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="VPL" nombre="Vargas Planta" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="SPL" nombre="Sardinero Planta" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="VBG" nombre="Vargas Bodega" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MargenesCentrocoste periodo="estemes" centrodecoste="SBG" nombre="Sardinero Bodega" />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          // display: 'inline-flex',
          // justifyContent: 'flex-start',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >


      </Box>
    </Box>

  )
}
export default Margenes;