import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { DataGrid } from '@mui/x-data-grid';
import { DateTime } from "luxon";
import { MDBSpinner } from 'mdb-react-ui-kit';
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosApi from '../servicios/axios.service';
import Gestiondeurl from '../servicios/url.service';


function DetalleUsuario(props) {
  // console.log(props)
  const [pageSize, setPageSize] = React.useState(25);



  const enlacedetalle = (p) => {
    // console.log(p.row.mes.toString().length)
    if (p.row.mes && p.row.mes.toString().length < 2) {
      p.row.mes = '0' + p.row.mes
    }
    return (
      <Link to={`/detallemes/${p.row.Año}/${p.row.mes}/${props.codalm}`}>{p.value}</Link>
    )
  }
  const [fechainicio, setFechainicio] = useState(DateTime.local().startOf('month').toFormat('yyyyMMdd'))
  const [fechafin, setFechafin] = useState(DateTime.local().minus({ 'days': 1 }).toFormat('yyyyMMdd'))
  const [value, setValue] = useState(props.periodo);
  const handleChange = (event) => {
    console.log(event.target.value)
    setValue(event.target.value);



  };
  const getMargen = (ventas, coste) => {

    const beneficio = ventas - coste
    let margen = beneficio / ventas
    margen = Number(margen * 100).toLocaleString()
    return `${margen} %`

  }
  const getVentas = (params) => {
    // console.log (params.row)

    return (
      params.row.ventastpv +
      params.row.ventasfacturas +
      params.row.ventasonline +
      params.row.ventasalbaranes +
      params.row.ventasinternas)

  }
  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    { field: 'id', headerName: 'Código', flex: 1 },
    { field: 'nomcli', headerName: 'Nombre', flex: 3 },
    {
      field: 'rol', headerName: 'Rol', flex: 1,
      type: 'singleSelect',
      valueOptions: [
        { value: '', label: 'Inactivo' },
        { value: 0, label: 'Usuario' },
        { value: 1, label: 'Administrador' },
      ],
      editable: true,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option.label;
      },

    },
  ];

  const rows = [
    {}, {}

  ];
  const [cargando, setCargando] = useState(true)

  const [filas, setFilas] = useState({})
  const numberFormat = (value) => {
    const valueFormatted = Number(value * 1).toLocaleString();
    return `${valueFormatted} €`
  }


  useEffect(() => {
    const getDatos = async () => {
      setCargando(true)
      let et = []
      let v = []
      let c = []
      const urlbase = await Gestiondeurl.DevuelveUrl()
      let url = urlbase + 'admin/detalleusuario'
      // let url = 'http://192.168.1.60/api/react/admin/detalleusuario'
      // console.log(DateTime.fromFormat(fechainicio, 'yyyyMMdd').setLocale('es').toFormat('dd - MMMM - yyyy'), fechafin)
      let data = {
        "codcli": props.codcli,


      }
      let config = {
        headers: { Authorization: `Bearer ` + localStorage.getItem('token') }


      }
      if (props.codcli) {
        AxiosApi.axiosApi
          .post(url, data)
          .then((response) => {
            console.log(response.data)

            setFilas(response.data.response[0])
            setCargando(false)
          })
          .catch((error) => {
            setCargando(false)
            console.log(error);
          });
      } else{
        setCargando(false)
      }


    }
    getDatos();

  }, [props.codcli]);
  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
    height: "600px",
    width: "80%",
  };
  return (

    <>

      <div style={{ width: '650'}}>

        {
          (() => {
            if (filas)
              return (<>
                {filas.NOMCLI}
                <br></br>
                {filas.NIFCLI}
              </>)
            if (!cargando)
              return (<span>Two</span>)
            else 
            return (
              <MDBSpinner className = 'mx-2' color = 'primary'>
            </MDBSpinner>
            )
          })()
        }

    </div>
    </>
  )

}

export default DetalleUsuario