import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import Logo from "./../recursos/logo-negro.png";
import {
  default as AuthService,
  default as authService,
} from "../servicios/auth.service";
// import './../custom.scss';
const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(4),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

function NavbarUsuarios() {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [ubicacion, setUbicacion] = useState(undefined);
  const classes = useStyles();
  const theme = useTheme();
  const [tiempoHastaExpirar, setTiempohastaExpirar] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const img = <img style={{ marginTop: 10 }} src="" />;
  useEffect(() => {
    authService.centro().then(r=>{
      setUbicacion(r)
    })
    setCurrentUser(authService.getCurrentUser());
    setTiempohastaExpirar(authService.tiempoHastaExpirar());
  }, []);
  const logOut = () => {
    AuthService.logout();
  };
  return (
    <>
      <Navbar bg="primary" expand="lg" variant="dark">
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src={Logo}
            // width="30"
            height="45"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              <Icon.HouseDoor />
              Inicio
            </Nav.Link>
            <Nav.Link as={Link} to="/clientes">
              Clientes
            </Nav.Link>
            <Nav.Link as={Link} to="/articulos">
              Articulos
            </Nav.Link>

            <>
              Barra de Usuarios
              {/* 
              <NavDropdown title="Administración" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="usuarios">
                  Usuarios
                </NavDropdown.Item>
                <NavDropdown.Item href="admin/modalidades">
                  Modalidades
                </NavDropdown.Item>
                <NavDropdown.Item href="admin/temporadas">
                  Temporadas
                </NavDropdown.Item>
                <NavDropdown.Item href="admin/categorias">
                  Categorías
                </NavDropdown.Item>
                <NavDropdown.Item href="admin/competiciones">
                  Competiciones
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Clubes</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Árbitros</NavDropdown.Item>
                <NavDropdown.Item href="admin/jugadores">
                  Jugadores
                </NavDropdown.Item>
              </NavDropdown> */}
            </>
          </Nav>
          {currentUser ? (
            <Nav className=" container-fluid justify-content-end">
              {/* <Nav.Link  href="/profile">Ver mi perfil {currentUser.nombre}</Nav.Link> */}
              <Countdown date={Date.now() + tiempoHastaExpirar} />
              <Nav.Link as={Link} to="login" onClick={logOut}>
                {currentUser.nombre}
                <br></br> {currentUser.rol} | Logout/Salir
              </Nav.Link>
              {ubicacion && <>{ubicacion}</>}
            </Nav>
          ) : (
            <Nav className="justify-content-end">
              <Nav.Link as={Link} to="login">
                Login/Acceder
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
export default NavbarUsuarios;
