import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { DateTime } from "luxon";
import { MDBSpinner } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosApi from "./../servicios/axios.service";
import Gestiondeurl from "./../servicios/url.service";
import Prohibido from "./../recursos/no-autorizado.jpg";
import authService from "../servicios/auth.service";
// var fechafin = DateTime.now().toFormat('yyyyMMdd')
// // var fechainicio = DateTime.local().startOf('year').toFormat('yyyyMMdd')
// var fechainicio = DateTime.local().minus({ 'years': 1 }).startOf('month').toFormat('yyyyMMdd')

function MargenesCentrocoste(props) {
  const tema = createTheme({
    palette: {
      // type: 'light',
      primary: {
        main: "#e3007e",
      },
      secondary: {
        main: "#7b1fa2",
      },
    },
  });
  // console.log(tema)

  const enlacedetalle = (p) => {
    // console.log(p.row.mes.toString().length)
    if (p.row.mes && p.row.mes.toString().length < 2) {
      p.row.mes = "0" + p.row.mes;
    }
    return (
      <Link to={`/detallemes/${p.row.Año}/${p.row.mes}/${props.codalm}`}>
        {p.value}
      </Link>
    );
  };
  const roles = ["admin"];
  const [autorizado, setAutorizado] = useState(
    roles.includes(authService.getCurrentUser().rol)
  );
  const [fechainicio, setFechainicio] = useState(
    DateTime.local().startOf("month").toFormat("yyyyMMdd")
  );
  const [fechafin, setFechafin] = useState(
    DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
  );
  const [value, setValue] = useState(props.periodo);
  const handleChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
    if (event.target.value == "ayer") {
      setFechainicio(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
      setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
    }
    if (event.target.value == "semana") {
      setFechainicio(DateTime.local().minus({ days: 7 }).toFormat("yyyyMMdd"));
      setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
    }
    if (event.target.value == "estemes") {
      setFechainicio(DateTime.local().startOf("month").toFormat("yyyyMMdd"));
      setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
    }
    if (event.target.value == "mespasado") {
      setFechainicio(
        DateTime.local()
          .minus({ months: 1 })
          .startOf("month")
          .toFormat("yyyyMMdd")
      );
      setFechafin(
        DateTime.local()
          .minus({ months: 1 })
          .endOf("month")
          .toFormat("yyyyMMdd")
      );
    }
    if (event.target.value == "esteano") {
      setFechainicio(DateTime.local().startOf("year").toFormat("yyyyMMdd"));
      setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
    }
    if (event.target.value == "anopasado") {
      setFechainicio(
        DateTime.local().minus({ year: 1 }).startOf("year").toFormat("yyyyMMdd")
      );
      setFechafin(
        DateTime.local().minus({ year: 1 }).endOf("year").toFormat("yyyyMMdd")
      );
    }
    if (event.target.value == "mes") {
      setFechainicio(DateTime.local().minus({ days: 31 }).toFormat("yyyyMMdd"));
      setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
    }
    if (event.target.value == "ano") {
      setFechainicio(
        DateTime.local().minus({ days: 366 }).toFormat("yyyyMMdd")
      );
      setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
    }
  };
  const getMargen = (ventas, coste) => {
    const beneficio = ventas - coste;
    let margen = beneficio / ventas;
    margen = Number(margen * 100).toLocaleString();
    return `${margen} %`;
  };
  const getVentas = (params) => {
    // console.log (params.row)

    return (
      params.row.ventastpv +
      params.row.ventasfacturas +
      params.row.ventasonline +
      params.row.ventasalbaranes +
      params.row.ventasinternas
    );
  };
  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "almacen",
      headerName: "Almacén",
      width: 90,
      align: "center",
      headerClassName: "Datagrid-rotado",
    },
    {
      field: "seccion",
      headerName: "Sección",
      width: 115,
      align: "center",
      headerClassName: "Datagrid-rotado",
    },
    {
      field: "ventastpv",
      headerName: "Ventas TPV",
      width: 160,
      headerClassName: "Datagrid-rotado",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: "costetpv",
      headerName: "Coste TPV",
      width: 115,
      align: "center",
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: "margenTPV",
      headerName: "Margen TPV",
      width: 115,
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 100).toLocaleString();
        return `${valueFormatted} %`;
      },
      valueGetter: getMargen,
      align: "center",
    },
    {
      field: "ventasfacturas",
      headerName: "Ventas por Facturas",
      width: 115,
      align: "center",
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: "ventasonline",
      headerName: "Ventas online",
      width: 115,
      align: "center",
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: "ventasalbaranes",
      headerName: "Ventas por albaranes",
      width: 115,
      align: "center",
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: "ventasinternas",
      headerName: "Ventas internas",
      width: 115,
      align: "center",
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: "totalventas",
      headerName: "Total Ventas",
      width: 115,
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
      valueGetter: getVentas,
      align: "center",
    },
    {
      field: "comprasinternas",
      headerName: "Compras internas",
      width: 115,
      align: "center",
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: "compras",
      headerName: "Compras",
      width: 115,
      align: "center",
      headerClassName: "Datagrid-rotado",

      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
  ];

  const rows = [{}, {}];
  const [cargando, setCargando] = useState(true);

  const [filas, setFilas] = useState({});
  const numberFormat = (value) => {
    const valueFormatted = Number(value * 1).toLocaleString();
    return `${valueFormatted} €`;
  };

  var datos = {};
  useEffect(() => {
    const getDatos = async () => {
      setCargando(true);
      let et = [];
      let v = [];
      let c = [];
      const urlbase = await Gestiondeurl.DevuelveUrl();
      // console.log(urlbase)
      // Gestiondeurl.DevuelveUrl()
      // .then (url=>{
      //   console.log(url)
      // })
      let url = urlbase + "margenescentrocoste";
      // let url = 'http://192.168.1.60/api/react/' + 'margenescentrocoste'
      // console.log(DateTime.fromFormat(fechainicio, 'yyyyMMdd').setLocale('es').toFormat('dd - MMMM - yyyy'), fechafin)
      let data = {
        fechainicio: fechainicio,
        fechafin: fechafin,
        centrocoste: props.centrodecoste,
      };
      let config = {
        headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
      };
      AxiosApi.axiosApi
        .post(url, data)
        .then((response) => {
          console.log(response.data);

          setFilas(response.data.response[0]);
          setCargando(false);
          // response.data.response.map((e) => {
          //   et.push(e.e.almacen)
          //   v.push(e.seccion)
          //   c.push(e.clientes)
          // })
        })
        .catch((error) => {
          setCargando(false);
          console.log(error);
        });
    };
    if (autorizado) getDatos();
  }, [value]);

  return (
    <>
      <ThemeProvider theme={tema}>
        <Card sx={{ minWidth: "99%" }}>
          <CardHeader
            avatar={
              <Avatar
                sx={{
                  bgcolor:
                    props.centrodecoste.charAt(0) == "V"
                      ? "primary.main"
                      : "secondary.main",
                }}
                aria-label="recipe"
              >
                {props.centrodecoste}
              </Avatar>
            }
            title={props.nombre}
            subheader={`
                   Del ${DateTime.fromFormat(fechainicio, "yyyyMMdd")
                     .setLocale("es")
                     .toFormat("dd/MMMM/yyyy")}
                    \u00A0 al ${DateTime.fromFormat(fechafin, "yyyyMMdd")
                      .setLocale("es")
                      .toFormat("dd/MMMM/yyyy")}
                    `}
          />
          {!cargando && autorizado ? (
            <>
              <CardContent>
                <FormControl>
                  <Box sx={{ minWidth: "99%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Seleccionar Período
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Período"
                        onChange={handleChange}
                        disabled={cargando}
                      >
                        <MenuItem value={"ayer"}>Ayer</MenuItem>
                        <MenuItem value={"semana"}>Última semana</MenuItem>
                        <MenuItem value={"estemes"}>Este Mes</MenuItem>
                        <MenuItem value={"mespasado"}>El mes pasado</MenuItem>
                        <MenuItem value={"esteano"}>Este año</MenuItem>
                        <MenuItem value={"anopasado"}>El año pasado</MenuItem>
                        <MenuItem value={"mes"}>Últimos 30 días</MenuItem>
                        <MenuItem value={"ano"}>Último año</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </FormControl>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: "99%" }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell component="th" scope="row">
                          Ventas por TPV
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {filas.ventastpv && numberFormat(filas.ventastpv)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={10}>
                        <TableCell component="th" scope="row">
                          Coste TPV
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {numberFormat(filas.costetpv)}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={11}
                        sx={{
                          bgcolor:
                            props.centrodecoste.charAt(0) == "V"
                              ? "primary.main"
                              : "secondary.main",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Margen TPV
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {getMargen(filas.ventastpv, filas.costetpv)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={2}>
                        <TableCell component="th" scope="row">
                          Ventas online
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {numberFormat(filas.ventasonline)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={3}>
                        <TableCell component="th" scope="row">
                          Ventas por Facturas
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {numberFormat(filas.ventasfacturas)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={4}>
                        <TableCell component="th" scope="row">
                          Ventas por Albaranes
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {numberFormat(filas.ventasalbaranes)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={5}>
                        <TableCell component="th" scope="row">
                          Ventas Internas
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {numberFormat(filas.ventasinternas)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={6}>
                        <TableCell
                          sx={{
                            color:
                              props.centrodecoste.charAt(0) == "V"
                                ? "primary.main"
                                : "secondary.main",
                          }}
                          component="th"
                          scope="row"
                        >
                          Compras
                        </TableCell>
                        <TableCell
                          sx={{
                            color:
                              props.centrodecoste.charAt(0) == "V"
                                ? "primary.main"
                                : "secondary.main",
                          }}
                          component="th"
                          scope="row"
                        >
                          - {numberFormat(filas.compras)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={7}>
                        <TableCell
                          sx={{
                            color:
                              props.centrodecoste.charAt(0) == "V"
                                ? "primary.main"
                                : "secondary.main",
                          }}
                          component="th"
                          scope="row"
                        >
                          Compras Internas
                        </TableCell>
                        <TableCell
                          sx={{
                            color:
                              props.centrodecoste.charAt(0) == "V"
                                ? "primary.main"
                                : "secondary.main",
                          }}
                          component="th"
                          scope="row"
                        >
                          - {numberFormat(filas.comprasinternas)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </>
          ) : !autorizado ? (
            <>
              <div className="col-md-12">
                <div className="card card-container">
                  <img
                    src={Prohibido}
                    alt="profile-img"
                    className="profile-img-card"
                  />
                </div>
              </div>
            </>
          ) : (
            // <CircularProgress/>
            <MDBSpinner className="mx-2" color="primary"></MDBSpinner>
          )}
        </Card>
      </ThemeProvider>
    </>
  );
}

export default MargenesCentrocoste;
