import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import authService from "../servicios/auth.service";
// import Gestiondeurl from "./../servicios/url.service";
// import AxiosApi from "./../servicios/axios.service";
import { Line } from "react-chartjs-2";
import CircularProgress from "@material-ui/core/CircularProgress";

import Prohibido from "./../../recursos/no-autorizado.jpg";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

var fechafin = DateTime.now().toFormat("yyyyMMdd");
var fechainicio = DateTime.local().startOf("year").toFormat("yyyyMMdd");
var fechainicio = DateTime.local()
  .minus({ months: 50 })
  .startOf("month")
  .toFormat("yyyyMMdd");

const labels = ["January", "February", "March", "April", "May", "June", "July"];
const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      min: 0,
    },
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};
const data = {
  labels,
  datasets: [
    {
      label: "Ventas",
      data: [1000, 500, 350, 450, 560, 750, 952],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Clientes",
      data: [1000, 500, 350, 450, 560, 750, 952],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

function Clientestienda({ tienda, filas, ventas, etiquetas, clientes }) {
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  const roles = ["admin"];
  const [cargando, setCargando] = useState(true);

  const [autorizado, setAutorizado] = useState(true);

  const [dataOptions, setDataOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 4000,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Clientes por Tienda",
      },
    },
  });
  const [dataChart, setDataChart] = useState({
    labels: etiquetas,
    datasets: [
      {
        label: "Clientes " + tienda,
        data: clientes,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  });
  var datos = {};
  // console.log(process.env);
  useEffect(() => {
    setCargando(false);
    //       setDataChart({
    //         labels: etiquetas,
    //         datasets: [{
    //           label: 'Ventas sin IVA ' + tienda,
    //           data: ventas,
    //           borderColor: 'rgb(255, 99, 132)',
    //           backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         }]

    // })
  }, [etiquetas, ventas, tienda]);
  useEffect(() => {
    // const getDatos = async () => {
    //     let et = [];
    //     let v = [];
    //     let c = [];
    //     const urlbase = await Gestiondeurl.DevuelveUrl();
    //     // console.log('autorizado ' + autorizado)
    //     // Gestiondeurl.DevuelveUrl()
    //     // .then (url=>{
    //     //   console.log(url)
    //     // })
    //     let url = "https://empleados.diferente.es/api/" + "ventasmensuales";
    //     // url = "../../api/react/ventasmensuales";
    //     // if (process.env.NODE_ENV !== "production") {
    //     //   url = process.env.REACT_APP_URL_BASE + "ventasmensuales";
    //     // }
    //     // console.log(process.env, url);
    //     let data = {
    //         fechainicio: fechainicio,
    //         fechafin: fechafin,
    //         almacen: props.codalm,
    //     };
    //     if (autorizado) {
    //         AxiosApi.axiosApi
    //             .get(url, { params: data })
    //             .then((response) => {
    //                 // console.log("status | " + response.status)
    //                 setCargando(false);
    //                 if (response != "403") {
    //                     setAutorizado(true);
    //                 } else {
    //                     setAutorizado(false);
    //                 }
    //                 if (response.status != 401) {
    //                     response.data.datos.map((e) => {
    //                         et.push(e.Mesano);
    //                         v.push(e.ventas);
    //                         c.push(e.clientes);
    //                     });
    //                     setDataChart({
    //                         labels: et,
    //                         datasets: [
    //                             {
    //                                 label:
    //                                     "Clientes " +
    //                                     response.data.datos[0].tienda,
    //                                 data: c,
    //                                 borderColor: "rgb(53, 162, 235)",
    //                                 backgroundColor:
    //                                     "rgba(53, 162, 235, 0.5)",
    //                             },
    //                         ],
    //                     });
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 if (error.response.status == 401) {
    //                     console.warn(error.response.status == 401);
    //                     setCargando(false);
    //                     setAutorizado(false);
    //                 }
    //             });
    //     }
    // };
    // getDatos();
  }, []);

  return (
    <>
      {!cargando && autorizado ? (
        <div className="card" style={{ height: "500px" }}>
          <Line data={dataChart} options={dataOptions} />
        </div>
      ) : !autorizado ? (
        <>
          <div className="col-md-12">
            <div className="card card-container">
              <img
                src={Prohibido}
                alt="profile-img"
                className="profile-img-card"
              />
            </div>
          </div>
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

export default Clientestienda;
