import React from "react";
import AxiosApi from "./../../servicios/axios.service";
import { Box, Grid, makeStyles} from "@material-ui/core";
import DataTable from "react-data-table-component";
import { colores,conditionalRowStyles,customStyles } from "../Auxiliares/Helpers";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import { makeStyles } from "@mui/styles";
function CambiosPrecio() {


  const [cambios, setCambios] = React.useState([]);

  const columns = [
    {
      name: "Código",
      selector: (row) => row.codart,
      sortable: true,
    //   maxWidth: "20px",
      //   maxWidth: '2rem',
      //   grow: 5,
      // added line here
      width: "90px",
      style:{
        flex:'unset',
      },
      headerStyle: (selector, id) => {
        return { textAlign: "center" }; // removed partial line here
      },
    },
    {
      name: "Descripcion",
      selector: (row) => row.descart,
      sortable: true,
      width: "250px",
    //   maxWidth: "60px",
      //   grow: 70,
    },
    // {
    //   name: "Precio anterior",
    //   selector: (row) => row.precio_anterior,
    //   sortable: true,
    //   hide:'sm',
    //   headerStyle: (selector, id) => {
    //     return { textAlign: "center"}; // removed partial line here
    //   },
    //   width: "120px",
    //   cell: (row) =>

    //   <div style={{ width: '80px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
    //   {Number(row.precio_anterior).toFixed(2).toLocaleString("es-ES", {
    //     style: "currency",
    //     currency: "EUR",
    //   }) + " €"}
    // </div>,
    // },
    {
      name: "Precio Nuevo",
      selector: (row) => row.precio_nuevo,
      sortable: true,
    //   maxWidth: "20px",
      //   grow: 10,
      headerStyle: (selector, id) => {
        return { textAlign: "center"}; // removed partial line here
      },
      width: "120px",
      cell: (row) =>
      <div style={{ width: '80px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {Number(row.precio_nuevo).toFixed(2).toLocaleString("es-ES", {
        style: "currency",
        currency: "EUR",
      }) + " €"}
    </div>,
    },
    {
      name: "",
      selector: (row) => row.precio_nuevo > row.precio_anterior,
      sortable: true,
      width: "70px",
    //   hide:'sm',
      //   grow: 10,
    //   maxWidth: "20px",
      cell: (row) => (
        row.precio_nuevo > row.precio_anterior ? 
        <ArrowUpwardIcon style={{ color: 'green' }} /> : 
        <ArrowDownwardIcon style={{ color: 'red' }} />
      ),
    }

  ]
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosApi.axiosApi.get(
          "https://empleados.diferente.es/api/articulos/cambiosdeprecio"
        );
        setCambios(response.data.cambios);
        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {cambios.length > 0 ? (
        <Grid
          columns={12}
          container
          justifyContent="center" // Centra horizontalmente los elementos hijos
          alignItems="center" // Centra verticalmente los elementos hijos
          style={{ minHeight: "10vh" }}
        >
          <Grid
            style={{ textAlign: "center" }}
            className="card"
            item
            xs={12}
            md={4}
          >
            <h3>Cambios de Precio</h3>
            <DataTable
              // className="card"
              columns={columns}
              data={cambios}
              customStyles={customStyles}
              pagination
              style={{ width: 'auto' }}
            //   rowClassName={(row) => {
            //     if (row.centrocoste === "Planta") {
            //       return classes.filaPlanta;
            //     } else {
            //       return "";
            //     }
            //   }}
              // paginationPerPage={15}
              // paginationRowsPerPageOptions={[15, 30, 45]}
              // paginationComponentOptions={
              //     paginationComponentOptions
              // }
              dense
              // onRowClicked={clickenfilatabla}
              // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              conditionalRowStyles={conditionalRowStyles}
              // subHeader
              // subHeaderComponent={subHeaderComponentMemo}
              // selectableRows
              // persistTableHead

              // selectableRows
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default CambiosPrecio;
