import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField,
    Button,
    Link,
    Typography,
} from '@material-ui/core';
import logo from './../recursos/cadevesa.png'
import AuthService from "./../servicios/auth.service";
import { useNavigate} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    form: {
        width: '300px',
        marginTop: theme.spacing(4),
    },
    logo: {
        width: '100px',
        height: '100px',
        margin: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginPage = () => {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const navigate= useNavigate()
    React.useEffect(()=>{
        document.title='Cadevesa | Acceso al sistema'
    })
    const handleLogin = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        // form.current.validateAll();

        
            AuthService.login(username, password).then(
                () => {
                    navigate("/");
                    // window.location.reload();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
       
    };
    // Enviar datos de inicio de sesión a la API de WordPress


    return (
        <div className={ classes.root}>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Typography variant="h5">Acceso al Sistema</Typography>
            <form className={classes.form} onSubmit={handleLogin}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Nombre de usuario"
                    name="username"
                    autoComplete="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Contraseña"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.submit}
                >
                    Iniciar sesión
                </Button>
                <Link href="/forgot-password">¿Olvidaste tu contraseña?</Link>
                <Link href="/register">Registrarse</Link>
            </form>
            {message && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginPage;