import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import Logo from "./../recursos/logo-negro.png";
import Image from "react-bootstrap/Image";
import Avatar from "@mui/material/Avatar";
import {
  default as AuthService,
  default as authService,
} from "./../servicios/auth.service";
import axios from "axios";
import AxiosApi from "./../servicios/axios.service";
import { useMiContexto } from "../contexto";
// import './../custom.scss';
const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(4),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

function Navbar2() {
  const { miEstado } = useMiContexto();
  const [currentUser, setCurrentUser] = useState([]);
  const [perfil, setPerfil] = useState({});
  const classes = useStyles();
  const theme = useTheme();
  // const [tiempoHastaExpirar, setTiempohastaExpirar] = useState(0);
  const [centro, setCentro] = useState("Fuera de Diferente");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const img = <img style={{ marginTop: 10 }} src="" />;
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data.ip);
    switch (res.data.ip) {
      case "80.38.219.243":
        setCentro("Diferente Vargas");
        break;
      case "88.10.196.152":
        setCentro("Diferente Sardinero");
        break;
      case "90.174.90.152":
        setCentro("Diferente Almacén");
        break;
      default:
        setCentro("FUERA DE DIFERENTE");
    }
    // setIP(res.data.ip);
  };
  const nivel1 = ["*", "admin"];
  const nivel2 = ["*", "admin", "adjuntocontrol"];
  const nivel3 = ["*", "admin", "adjuntocontrol", "encargado"];
  useEffect(() => {
    let url = "https://empleados.diferente.es/api/miperfil";
    AxiosApi.axiosApi
      .get(url)
      .then((response) => {
        console.log(response);
        setPerfil(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log(authService.getCurrentUser());
    // setCurrentUser(authService.getCurrentUser());
    // // setTiempohastaExpirar(authService.tiempoHastaExpirar());
    // // getData()
    // console.log('En Navbar ', currentUser)
    // console.log("miEstado ", miEstado);
  }, [miEstado]);
  const logOut = () => {
    AuthService.logout();
  };
  return (
    <>
      {perfil && Object.keys(perfil).length != 0 ? (
        <Navbar bg="primary" expand="lg" variant="dark">
          <Navbar.Brand as={Link} to="/">
            <img
              alt=""
              src={Logo}
              // width="30"
              height="45"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/">
                <Icon.HouseDoor />
                Inicio
              </Nav.Link>
              {nivel1.includes(perfil.OBSERVACIONES.rol) ? (
                <Nav.Link as={Link} to="/detallemes">
                  Detalle Mes
                </Nav.Link>
              ) : (
                <></>
              )}

              <Nav.Link as={Link} to="/clientes">
                Clientes
              </Nav.Link>
              <Nav.Link as={Link} to="/articulos">
                Articulos
              </Nav.Link>

              <>
                {/* <Navbar.Text>{currentUser.rol}</Navbar.Text> */}
                {nivel1.includes(perfil.OBSERVACIONES.rol) ? (
                  <Nav.Link as={Link} to="margenescentrodecoste">
                    Margenes por Centro de Coste
                  </Nav.Link>
                ) : (
                  <></>
                )}

                {nivel2.includes(perfil.OBSERVACIONES.rol) ? (
                  <NavDropdown title="Administración" id="basic-nav-dropdown">
                    {nivel1.includes(perfil.OBSERVACIONES.rol) ? (
                      <NavDropdown.Item as={Link} to="usuarios">
                        Usuarios
                      </NavDropdown.Item>
                    ) : (
                      <></>
                    )}
                    {nivel2.includes(perfil.OBSERVACIONES.rol) ? (
                      <NavDropdown.Item as={Link} to="fichajesadmin">
                        Fichajes
                      </NavDropdown.Item>
                    ) : (
                      <></>
                    )}
                  </NavDropdown>
                ) : (
                  <></>
                )}
              </>
            </Nav>
            {perfil && perfil.CODCLI ? (
              <Nav className=" container-fluid justify-content-end">
                {/* <Nav.Link  href="/profile">Ver mi perfil {currentUser.nombre}</Nav.Link> */}

                {/* <Image
                              src={
                                  "http://empleados.diferente.es/public/" +
                                  currentUser[1].imagen
                              }
                              style={{
                                  borderRadius: "50%",
                                  height: "30px",
                                  width: "30px",
                              }}
                          ></Image> */}
                <Avatar
                  alt={perfil.NOMCLI}
                  src={
                    "https://empleados.diferente.es/public/" +
                    perfil.OBSERVACIONES.imagen
                  }
                  sx={{ width: 36, height: 36 }}
                />
                <Nav.Item>
                  <Nav.Link as={Link} to="perfil">
                    <div className="NAvLinkDiv">
                    <Navbar.Text>Mi perfil</Navbar.Text>
                    </div>
                    
                    <Navbar.Text>{perfil.NOMCLI}</Navbar.Text>
                  </Nav.Link>
                  {/* <Navbar.Text>{perfil.OBSERVACIONES.rol}</Navbar.Text> */}
                </Nav.Item>

                <Nav.Link as={Link} to="login" onClick={logOut}>
                  Logout/Salir
                </Nav.Link>
              </Nav>
            ) : (
              <Nav className="justify-content-end">
                <Nav.Link as={Link} to="login">
                  Login/Acceder
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Navbar>
      ) : (
        <>
          <div>No hay barra</div>
        </>
      )}
    </>
  );
}
export default Navbar2;
