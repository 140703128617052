import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { MDBSpinner } from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useState ,useMemo} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AxiosApi from "../servicios/axios.service";
import Gestiondeurl from "../servicios/url.service";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridFilterPanel } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import SmartDataTable from "react-smart-data-table";
import DataTable from "react-data-table-component";
import Avatar from "@mui/material/Avatar";
import styled from "styled-components";
// import { Button } from "@mui/material";
// import "react-smart-data-table/dist/react-smart-data-table.css";
// const useFakeMutation = () => {
//   return useCallback(
//     (user) =>
//       new Promise(async (resolve, reject) => {
//         let data = {
//           codcli: user.id,
//           rol: user.rol,
//         };
//         const urlbase = await Gestiondeurl.DevuelveUrl();
//         // console.log(urlbase);
//         let url = urlbase + "admin/updateusuariorol";
//         // let url = 'http://192.168.1.60/api/react/admin/updateusuariorol'
//         // setCargando(true)
//         AxiosApi.axiosApi
//           .post(url, data)
//           .then((response) => {
//             // console.log(response);
//             if (response.data.response === "ok") {
//               // setCargando(false)
//               resolve({ ...user });
//               // setSnackbar({ children: 'User successfully saved', severity: 'success' });
//             }

//             return response;
//           })
//           .catch((error) => {
//             // setCargando(false)
//             console.log(error);
//           });
//       }),

//     []
//   );
// };
const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `
const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por nombre"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
function Usuarios(props) {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [cargando, setCargando] = useState(true);

  const [filas, setFilas] = useState([]);
  // const parseRolUsuario = function (value) {
  //   console.log(value);

  //   return String(value);
  // };
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
};

const filteredItems = filas.filter(
    (item) =>
        // item.NOMCLI &&
        // item.NOMCLI.toLowerCase().includes(filterText.toLowerCase())
        (item.NOMCLI && item.NOMCLI.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.CODCLI && item.CODCLI.toString().toLowerCase().includes(filterText.toLowerCase()))
);

const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    };

    return (
        <FilterComponent
            onFilter={
                (e) => {
                    console.log(e)
                    //  e.preventDefault();
                    setFilterText(e.target.value)
                }
            }
            onClear={handleClear}
            filterText={filterText}
        />
    );
}, [filterText, resetPaginationToggle]);
  const theme = createTheme(
    {
      palette: {
        // type: 'light',
        primary: {
          main: "#e3007e",
        },
        secondary: {
          main: "#f50057",
        },
      },
    },
    esES // x-data-grid translations
  );
  const clickenfila = (params) => {
    // console.log(params.row);
    props.setCodcli(params.row.id);
  };
  // const mutateRow = useFakeMutation();
  // console.log(tema)
  const [snackbar, setSnackbar] = useState(null);
  // const [filt, setFilt] = useState([]);
  const [recargar, setRecargar] = React.useState(false);
  const handleCloseSnackbar = () => setSnackbar(null);
  // const processRowUpdate = useCallback(
  //   async (newRow) => {
  //     // console.log(newRow);

  //     // Make the HTTP request to save in the backend
  //     const response = await mutateRow(newRow);
  //     setSnackbar({
  //       children: "Usuario actualizado correctamente",
  //       severity: "success",
  //     });
  //     return response;
  //   },
  //   [mutateRow]
  // );

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const [fechainicio, setFechainicio] = useState(
    DateTime.local().startOf("month").toFormat("yyyyMMdd")
  );
  const [fechafin, setFechafin] = useState(
    DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
  );
  const [value, setValue] = useState(props.periodo);


  function parseRol(params) {
    // console.log(params);
    // console.log(params.field);
    const colDef = params.api.getColumn(params.field);
    // console.log(colDef.valueOptions);
    const option2 = colDef.valueOptions.find((valor) => {
      // console.log(valor);
      return valor.value === params.value;
    });
    const option = colDef.valueOptions.find(
      ({ value: optionValue }) => params.value === optionValue
    );
    // console.log(option2);
    return option2.label;
  }
const actualizarfila =(index,rol,row) =>{

  console.log(rol,index,row)
  const newFilas= [...filas]
    newFilas.forEach(fila=>{
      if(fila.CODCLI===row.CODCLI){
        if (!fila.OBSERVACIONES){
          fila.OBSERVACIONES={}
        }
        fila.OBSERVACIONES.rol=rol
      }
    })
  // newFilas[index].OBSERVACIONES.rol=rol
  setFilas(newFilas)
}
  const SelectorRol = ({row,index}) => {
    // console.log(row,index)
    const rolinicial = row.OBSERVACIONES && row.OBSERVACIONES.rol ? row.OBSERVACIONES.rol : '0'
    // console.log(rolinicial)
    const [rol,setRol]=useState(rolinicial)
    const handleChange = (event,codcli) => {
      console.log('handleChange', codcli)
      // console.log(event.target.value);
      // setValue(event.target.value);
      const rol = event.target.value
      let url = "https://empleados.diferente.es/api/actualizarrol";

      AxiosApi.axiosApi
        .post(url,{
          codcli:codcli,
          rol:rol,
        })
        .then((response) => {
          console.log(response);
          // setCargando(false);
          setSnackbar({
            children: "Usuario actualizado correctamente",
            severity: "success",
          });
          // setRecargar(!recargar)
         actualizarfila(index,rol,row)
        })
        .catch((error) => {
          // setCargando(false);
          console.log(error);
          setSnackbar({ children: "ha ocurrido un error", severity: "error" });
        });

      
    };
    useEffect(()=>{
      console.log('useEffect', rol)
      const rolinicial = row.OBSERVACIONES && row.OBSERVACIONES.rol ? row.OBSERVACIONES.rol : '0'
      setRol(rolinicial)
    },[rol,row])
    // console.log(row.row)
    
    // console.log(rol,typeof(rol))
    return (
      <Select
        value={rol}
        // native
        // label="Tienda"
        onChange={(evt,value)=>handleChange(evt,row.CODCLI)}
        defaultValue={""}
        // style={{marginTop:20,}}
      >
        <MenuItem value='0'>
          <em>Seleccionar Rol</em>
        </MenuItem>
        <MenuItem value="empleado">
          <em>Empleado</em>
        </MenuItem>
        <MenuItem value="adjuntocontrol">
          <em>Adjunto Control</em>
        </MenuItem>
        <MenuItem value="encargado">
          <em>Encargado</em>
        </MenuItem>
        <MenuItem value="ventas">
          <em>Acceso Ventas</em>
        </MenuItem>
        <MenuItem value="admin">
          <em>Administrador</em>
        </MenuItem>
      </Select>
    );
  };
  const columns = [
    {
      name: "Código",
      selector: (row) => row.CODCLI,
      sortable: true,
      width: "80px",
    },
    ,
    {
      name: "",
      selector: (row) => {
        return row.OBSERVACIONES && row.OBSERVACIONES.imagen ? (
          <Avatar
            alt={row.NOMCLI}
            src={
              "https://empleados.diferente.es/public/" +
              row.OBSERVACIONES.imagen
            }
            sx={{ width: 36, height: 36 }}
          />
        ) : (
          <Avatar alt={row.NOMCLI} sx={{ width: 36, height: 36 }} />
        );
      },
      sortable: true,
      // grow: 1,
      width: "80px",
    },
    {
      name: "Nombre",
      selector: (row) => row.NOMCLI,
      sortable: true,
      grow: 4,
    },
    {
      name: "Rol",
      selector: (row,index) => {
        // console.log(row,index)
        return row.OBSERVACIONES && row.OBSERVACIONES.rol
          ? <SelectorRol index={index} row={row}/>
          : <SelectorRol index={index} row={row}/>
      },
      sortable: true,
      grow: 4,
    },
  ];

  const rows = [{}, {}];

  const numberFormat = (value) => {
    const valueFormatted = Number(value * 1).toLocaleString();
    return `${valueFormatted} €`;
  };
  const clickenfilatabla = (event, { rowData, rowIndex, tableData }) => {
    // The following results should be identical
    console.log(event);
    // props.setCodcli(rowData.id);
  };
  useEffect(() => {
    const getDatos = async () => {
      setCargando(true);
      let url = "https://empleados.diferente.es/api/empleados";

      AxiosApi.axiosApi
        .get(url)
        .then((response) => {
          console.log(response);

          setFilas(response.data);
          setCargando(false);
        })
        .catch((error) => {
          setCargando(false);
          console.log(error);
        });
    };
    getDatos();
  }, [recargar]);
  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
    height: "600px",
    width: "80%",
  };
  return (
    <>
      <></>

      {!cargando ? (
        <ThemeProvider theme={theme}>
          <div
            sx={{ m: 2 }}
            style={{
              margin: "2em",
              width: "650",
              height: "800px",
              maxHeight: "800px",
            }}
          >
            <DataTable
              columns={columns}
              data={filteredItems}
              subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            // selectableRows
                            persistTableHead
              pagination
              paginationPerPage={15}
              paginationComponentOptions={
                paginationComponentOptions
            }
              onRowClicked={clickenfilatabla}
              paginationRowsPerPageOptions={[15, 30, 45, 60]}
            />
            {/* <SmartDataTable
                          data={filas}
                          name="test-table"
                          className="ui compact selectable table"
                          sortable="true"
                          filterable="true"
                          perPage={25}
                          onRowClick={clickenfilatabla}
                      /> */}

            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
              >
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
              </Snackbar>
            )}
          </div>
        </ThemeProvider>
      ) : (
        // <CircularProgress/>
        <MDBSpinner className="mx-2" color="primary"></MDBSpinner>
      )}
    </>
  );
}

export default Usuarios;
