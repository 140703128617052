import Ventaseurostienda from "./../component/VentasEurosTienda";
import Clientestienda from "./../component/ClientesTienda";
import DataGdridVentasClienets from "./../component/DataGridVentasClientes";
import authService from "../servicios/auth.service";
import Margenes from "../component/Margenes";
import { Box, Grid, Paper } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CambiosPrecio from "../component/Home/CambiosPrecio";
// import roles from "../recursos/tipousuarios";
import React, { useState } from "react";
import SubirImagen from "../component/Perfil/SubirImagen";
import EstadoTiendas from "../component/EstadoTiendas/EstadoTiendas";
function About() {
  // console.warn(roles);
  // const [esAdministrador, setEsAdmininistrador] = useState(
  //   authService.getCurrentUser().rol === "admin"
  // );
  // const [esEncargado, setEsEncargado] = useState(
  //   authService.getCurrentUser().rol === "encargado"
  // );
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <>
      <SubirImagen />
    </>
  );
}
export default About;
