import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DateTime } from "luxon";
// import 'chart.js/auto';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, getElementsAtEvent } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Gestiondeurl from './../servicios/url.service'
// import AxiosApi from './../servicios/axios.service'
import Prohibido from "./../../recursos/no-autorizado.jpg";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend

)
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    }
  },
};


function Ventaseurostienda({tienda,filas, ventas, etiquetas,clientes}) {
  const chartRef = useRef();
  const onClick = (event) => {
    console.log(getElementsAtEvent(chartRef.current, event));
  }
  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const [cargando, setCargando] = useState(true)
  const [autorizado, setAutorizado] = useState(true)
  const [dataOptions, setDataOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    // beginAtZero: true,
    scales: {
      y: {
        min: 100000,
      },
      
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ventas sin IVA por Tienda',
      },
    },
  })
  const [dataChart, setDataChart] = useState({
    labels: etiquetas,
    datasets: [{
      label: 'Ventas sin IVA ' + tienda,
      data: ventas,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }]

});

  var datos = {}
  useEffect(() => {
setCargando(false)
    //       setDataChart({
    //         labels: etiquetas,
    //         datasets: [{
    //           label: 'Ventas sin IVA ' + tienda,
    //           data: ventas,
    //           borderColor: 'rgb(255, 99, 132)',
    //           backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         }]

    // })
 
  }, [etiquetas,ventas,tienda]);

  return (
    <>

        <div className='card' style={{ height: "500px" ,padding:'1rem' }}>
          <Line
            ref={chartRef}
            onClick={onClick}
            data={dataChart}
            options={dataOptions}
          />
        </div>

    </>
  );

}

export default Ventaseurostienda