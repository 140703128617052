import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DateTime } from "luxon";
// import 'chart.js/auto';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, getElementsAtEvent } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import Gestiondeurl from './../servicios/url.service'
import AxiosApi from './../servicios/axios.service'
import Prohibido from "./../recursos/no-autorizado.jpg";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend

)
var fechafin = DateTime.now().toFormat('yyyyMMdd')
var fechainicio = DateTime.local().startOf('year').toFormat('yyyyMMdd')
var fechainicio = DateTime.local().minus({ 'months': 50 }).startOf('month').toFormat('yyyyMMdd')
// console.log(fechainicio, fechafin)

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    }
  },
};


function Ventaseurostienda(props) {
  const chartRef = useRef();
  const onClick = (event) => {
    console.log(getElementsAtEvent(chartRef.current, event));
  }
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const [cargando, setCargando] = useState(true)
  const [autorizado, setAutorizado] = useState(true)
  const [dataOptions, setDataOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    // beginAtZero: true,
    scales: {
      y: {
        min: 100000,
      },
      
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ventas sin IVA por Tienda',
      },
    },
  })
  const [dataChart, setDataChart] = useState({
    labels,
    datasets: [
      {
        label: 'Ventas sin IVA',
        data: [1000, 500, 350, 450, 560, 750, 952],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  });
  var datos = {}
  useEffect(() => {
    const getDatos = async () => {
      let et = []
      let v = []
      let c = []
      const urlbase = await Gestiondeurl.DevuelveUrl()
      // console.log(urlbase)
      // Gestiondeurl.DevuelveUrl()
      // .then (url=>{
      //   console.log(url)
      // })
      let url = "https://empleados.diferente.es/api/" + "ventasmensuales";
      let data = {
        "fechainicio": fechainicio,
        'fechafin': fechafin,
        'almacen': props.codalm,

      }
      let config = {
        headers: { Authorization: `Bearer ` + localStorage.getItem('token') }


      }
      AxiosApi.axiosApi
        .get(url, { params: data })
        .then((response) => {
          // console.log(response)
          setCargando(false)
          if (response != '403') {
            setAutorizado(true)
          } else {
            setAutorizado(false)
          }
          response.data.datos.map((e) => {
            // console.log(e)
            et.push(e.Mesano)
            v.push(e.ventas)
            c.push(e.clientes)
          })




          setDataChart({
            labels: et,
            datasets: [{
              label: 'Ventas sin IVA ' + response.data.datos[0].tienda,
              data: v,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
            ]
          });

        })
        .catch((error) => {
          console.log(error);
        });

    }
    getDatos();

  }, []);

  return (
    <>
      {!cargando && autorizado ? (
        <div style={{ height: "500px" }}>
          <Line
            ref={chartRef}
            onClick={onClick}
            data={dataChart}
            options={dataOptions}
          />
        </div>
      ) : !autorizado ? (
        <div className="col-md-12">
          <div className="card card-container">
            <img
              src={Prohibido}
              alt="profile-img"
              className="profile-img-card"
            />
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );

}

export default Ventaseurostienda