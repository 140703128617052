import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { MDBSpinner } from 'mdb-react-ui-kit';
// import Gestiondeurl from './../servicios/url.service'
// import AxiosApi from './../servicios/axios.service'

var fechafin = DateTime.now().toFormat('yyyyMMdd')
var fechainicio = DateTime.local().startOf('year').toFormat('yyyyMMdd')
var fechainicio = DateTime.local().minus({ 'years': 1 }).startOf('month').toFormat('yyyyMMdd')
// console.log(fechainicio, fechafin)


function DataGridVentasClientes({tienda,filas, ventas, etiquetas,clientes,codalm}) {
  const enlacedetalle = (p) => {
    // console.log(p.row.mes.toString().length)
    if (p.row.mes && p.row.mes.toString().length < 2) {
      p.row.mes = '0' + p.row.mes
    }
    return (
      <Link to={`/detallemes/${p.row.Año}/${p.row.mes}/${codalm}`}>{p.value}</Link>
    )
  }

  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'Mesano',
      headerName: 'Período',
      width: 100,
      editable: false,
      renderCell: enlacedetalle
    },
    {
      field: 'ventas',
      headerName: 'Ventas Sin Iva',
      type: 'number',
      width: 120,
      // editable: true,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
    },
    {
      field: 'clientes',
      headerName: 'Clientes',
      type: 'number',
      width: 110,
      // editable: true,
    },
    {
      field: 'tickettmedio',
      headerName: 'Ticket Medio',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 110,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value * 1).toLocaleString();
        return `${valueFormatted} €`;
      },
      // valueGetter: (params) => 
      //  params.getValue(params.id, 'Ventas')*1 /               params.getValue(params.id, 'Clientes')*1 
    },
  ];

  const rows = [

  ];
  const [cargando, setCargando] = useState(true)

  // const [filas, setFilas] = useState([
  //   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  //   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  //   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  //   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  //   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  //   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  //   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  //   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  //   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },

  // ])

  var datos = {}
  useEffect(() => {
    setCargando(false)
        //       setDataChart({
        //         labels: etiquetas,
        //         datasets: [{
        //           label: 'Ventas sin IVA ' + tienda,
        //           data: ventas,
        //           borderColor: 'rgb(255, 99, 132)',
        //           backgroundColor: 'rgba(255, 99, 132, 0.5)',
        //         }]
    
        // })
     
      }, [etiquetas,ventas,tienda,codalm]);
  useEffect(() => {
    // const getDatos = async () => {
    //   setCargando(true)
    //   let et = []
    //   let v = []
    //   let c = []
    //   const urlbase = await Gestiondeurl.DevuelveUrl()
    //   // console.log(urlbase)
    //   // Gestiondeurl.DevuelveUrl()
    //   // .then (url=>{
    //   //   console.log(url)
    //   // })
    //   let url = "https://empleados.diferente.es/api/" + "ventasmensuales";
    //   let data = {
    //     "fechainicio": fechainicio,
    //     'fechafin': fechafin,
    //     'almacen': props.codalm,

    //   }
    //   let config = {
    //     headers: { Authorization: `Bearer ` + localStorage.getItem('token') }


    //   }
    //   AxiosApi.axiosApi
    //       .get(url, { params: data })
    //       .then((response) => {
    //           // console.log(response.data)

    //           setFilas(response.data.datos);
    //           setCargando(false);
    //           response.data.datos.map((e) => {
    //               et.push(e.Mesano);
    //               v.push(e.ventas);
    //               c.push(e.clientes);
    //           });
    //       })
    //       .catch((error) => {
    //           setCargando(false);
    //           console.log(error);
    //       });

    // }
    // getDatos();

  }, []);

  return (
    <>
      {
        !cargando ?
          (
            <>
              <h1></h1>
              <div className='card' style={{ height: '500px' }}>
                <DataGrid
                  rows={filas}
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 15]}
                  pageSize={5}

                />
              </div>
            </>
          ) :
          // <CircularProgress/>
          <MDBSpinner className='mx-2' color='secondary'>
            {/* <span className='visually-hidden'>Loading...</span> */}
          </MDBSpinner>

      }
    </>

  )

}

export default DataGridVentasClientes